import { memo } from 'react';
import useHover from '../../hooks/useHover';
import styles from '../../styles/components/button/OrderButton.module.scss';

const OrderButton = memo(({ href }) => {
    useHover();

    return (
        <a
            className={`${styles.order} js-hover js-hover__btn__key`}
            href={href}
            data-hover-in-time="400"
            data-hover-out-time="0"
            target="_blank"
            rel="noopener noreferrer"
        >
            <span className={styles.orderText}>
                ORDER N
                <span className={styles.orderTextParent}>
                    O
                    <span
                        className={`${styles.orderTextParentInnerWord} orderTextParentInnerWord`}
                    >
                        <span
                            className={`${styles.orderTextWord} orderTextWord`}
                        >
                            O
                        </span>
                        <span
                            className={`${styles.orderTextWord} orderTextWord`}
                        >
                            O
                        </span>
                    </span>
                </span>
                <span className={styles.orderTextParent}>
                    W
                    <span
                        className={`${styles.orderTextParentInnerMark} orderTextParentInnerMark`}
                    >
                        <span
                            className={`${styles.orderTextWord} orderTextWord`}
                        >
                            !
                        </span>
                    </span>
                </span>
            </span>
        </a>
    );
});

OrderButton.displayName = 'OrderButton';

export default OrderButton;
