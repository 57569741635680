import Link from 'next/link';
import { memo } from 'react';
import Img from '../common/Img';
import styles from '../../../styles/components/section/top/Location.module.scss';

const Location = memo(() => {
    return (
        <section className={styles.container}>
            <Link href="/location">
                <a
                    className={`${styles.link} js-hover`}
                    data-hover-in-time="600"
                    data-hover-out-time="0"
                >
                    <div className={styles.img}>
                        <div className={styles.imgInner}>
                            <Img
                                hover={true}
                                src="/image/top/location_bg.jpg"
                                webpSrc="/image/top/location_bg.webp"
                                alt="STORE LOCATOR"
                            />
                        </div>
                    </div>
                    <div className={styles.content}>
                        <svg
                            className={styles.contentSvg}
                            viewBox="0 0 60 71"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M30 70.9375C31.5 70.9375 60 49.9375 60 30.3081C60 14.0872 46.5685 0.9375 30 0.9375C13.4315 0.9375 0 14.0872 0 30.3081C0 49.9375 28.5 70.9375 30 70.9375ZM30 41.4635C36.2132 41.4635 41.25 36.515 41.25 30.4108C41.25 24.3066 36.2132 19.3582 30 19.3582C23.7868 19.3582 18.75 24.3066 18.75 30.4108C18.75 36.515 23.7868 41.4635 30 41.4635Z"
                                fill="#F9F8F3"
                            />
                        </svg>
                        <h2 className={styles.contentTitle}>STORE LOCATOR</h2>
                        <div
                            className={`${styles.contentLink} js-hover js-hover__btn__white`}
                        >
                            <span
                                className={`${styles.contentLinkText} js-hover__btn__white-text`}
                            >
                                FIND A CRISP
                            </span>
                        </div>
                    </div>
                </a>
            </Link>
        </section>
    );
});

Location.displayName = 'Location';

export default Location;
