import Link from 'next/link';
import React, { memo } from 'react';
import useUserAgent from '../../../hooks/useUserAgent';
import OrderButton from '../../button/OrderButton';
import Img from '../common/Img';
import styles from '../../../styles/components/section/top/Yap.module.scss';

const Yap = memo(({ yapEntries, yapTitle }) => {
    const { device } = useUserAgent();

    return (
        <section className={styles.container}>
            <div className={styles.wrapper}>
                <div className={styles.header}>
                    <h2 className={styles.headerTitle}>
                        SWEEET YAP!
                        {/* {yapTitle.items[0].fields.title} */}
                    </h2>
                    {/* <Link href={yapTitle.items[0].fields.url}> */}
                    <Link href="/menu">
                        <a
                            className={`${styles.headerMore} js-hover js-hover__color__point`}
                            data-hover-in-time="600"
                            data-hover-out-time="0"
                        >
                            SEE
                            <span className={styles.headerMoreText}>MORE</span>
                        </a>
                    </Link>
                    {/* </Link> */}
                </div>
                <div className={styles.body}>
                    <ul className={styles.bodyList}>
                        {yapEntries.items.map((data, i) => {
                            return (
                                <li
                                    className={styles.bodyItem}
                                    key={data.fields.url}
                                >
                                    <Link href={data.fields.url}>
                                        <a
                                            className={`${styles.bodyItemLink} js-hover`}
                                            data-hover-in-time="600"
                                            data-hover-out-time="0"
                                        >
                                            <div
                                                className={`${styles.bodyItemImg} yapBodyItemImg`}
                                            >
                                                <Img
                                                    hover={true}
                                                    src={
                                                        `https:` +
                                                        data.fields.image.fields
                                                            .file.url +
                                                        `?fm=webp&q=35`
                                                    }
                                                    alt={data.fields.alt}
                                                />
                                            </div>
                                        </a>
                                    </Link>
                                    <div className={styles.bodyItemContent}>
                                        <h3
                                            className={
                                                styles.bodyItemContentTitle
                                            }
                                        >
                                            {data.fields.title}
                                        </h3>
                                        <p
                                            className={
                                                styles.bodyItemContentText
                                            }
                                        >
                                            {data.fields.description}
                                        </p>
                                        {device === 'deviceMobile' &&
                                            data.fields.dynamicLink && (
                                                <OrderButton
                                                    href={
                                                        data.fields.dynamicLink
                                                    }
                                                />
                                            )}
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        </section>
    );
});

Yap.displayName = 'Yap';

export default Yap;
