import { memo } from 'react';
import SwiperAlertKv from '../../ui/swiper/SwiperAlertKv';
import styles from '../../../styles/components/section/common/AlertKv.module.scss';

const AlertKv = memo(({ messages }) => {
    return (
        <div className={`${styles.container} alertKv`}>
            <div className={styles.swiper}>
                <SwiperAlertKv messages={messages} />
            </div>
        </div>
    );
});

AlertKv.displayName = 'AlertKv';

export default AlertKv;
