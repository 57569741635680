import { memo } from 'react';
import Img from './Img';
import styles from '../../../styles/components/section/common/Careers.module.scss';

const Careers = memo(({ serviceSiteFooterBanner }) => {
    return (
        <div className={styles.container}>
            <a
                className={`${styles.link} js-hover`}
                data-hover-in-time="600"
                data-hover-out-time="0"
                href={serviceSiteFooterBanner.items[0].fields.url}
                target="_blank"
                rel="noopener noreferrer"
            >
                <div className={styles.img}>
                    <Img
                        hover={true}
                        src={
                            `https:` +
                            serviceSiteFooterBanner.items[0].fields.image.fields
                                .file.url +
                            `?fm=webp&q=50`
                        }
                        alt={serviceSiteFooterBanner.items[0].fields.alt}
                    />
                </div>
            </a>
        </div>
    );
});

Careers.displayName = 'Careers';

export default Careers;
