import Link from 'next/link';
import React, { memo } from 'react';
import useWindowWidth from '../../../hooks/useWindowWidth';
import styles from '../../../styles/components/section/top/Kv.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Parallax } from 'swiper';
SwiperCore.use([Pagination, Parallax]);
import 'swiper/css';
import 'swiper/css/pagination';

const SwiperTopKv = memo(({ banners, siteDomein }) => {
    const { breakPoint, windowWidth } = useWindowWidth();

    if (banners.total > 1) {
        return (
            <Swiper
                className={styles.swiperList}
                slidesPerView={1}
                loop={true}
                parallax={true}
                speed={1200}
                pagination={{ clickable: true }}
                autoplay={{
                    delay: 4000,
                    disableOnInteraction: false,
                }}
            >
                {banners.items.map((banner) => {
                    const switchSrc =
                        windowWidth > breakPoint
                            ? banner.fields.pcImage.fields.file.url
                            : banner.fields.spImage.fields.file.url;
                    return (
                        <SwiperSlide
                            className={styles.swiperItem}
                            key={banner.sys.id}
                        >
                            {/* {banner.fields.url.includes(siteDomein) ? ( */}
                            {banner.fields.url.charAt(0) === '/' ? (
                                <Link href={`${banner.fields.url}`}>
                                    <a className={styles.swiperLink}>
                                        <div
                                            className={
                                                styles.swiperItemImgCover
                                            }
                                        >
                                            <div
                                                className={styles.swiperItemImg}
                                            >
                                                <img
                                                    className={
                                                        styles.swiperItemImgInside
                                                    }
                                                    src={
                                                        `https:` +
                                                        switchSrc +
                                                        `?fm=webp&q=80`
                                                    }
                                                    data-swiper-parallax="-6.5%"
                                                    alt={banner.fields.alt}
                                                />
                                            </div>
                                        </div>
                                        <section
                                            className={styles.swiperItemSection}
                                            data-swiper-parallax="-30%"
                                        >
                                            <h2
                                                className={
                                                    styles.swiperItemSectionTitle
                                                }
                                            >
                                                {banner.fields.title &&
                                                    banner.fields.title
                                                        .split('\n')
                                                        .map(
                                                            (string, index) => (
                                                                <React.Fragment
                                                                    key={index}
                                                                >
                                                                    {string}
                                                                    <br />
                                                                </React.Fragment>
                                                            )
                                                        )}
                                            </h2>
                                            <p
                                                className={
                                                    styles.swiperItemSectionText
                                                }
                                            >
                                                {banner.fields.shoulder}
                                            </p>
                                            <div
                                                className={
                                                    styles.swiperItemSectionLink
                                                }
                                            >
                                                <span
                                                    className={
                                                        styles.swiperItemSectionLinkText
                                                    }
                                                >
                                                    MORE
                                                </span>
                                            </div>
                                        </section>
                                    </a>
                                </Link>
                            ) : (
                                <a
                                    href={`${banner.fields.url}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={styles.swiperLink}
                                >
                                    <div className={styles.swiperItemImgCover}>
                                        <div className={styles.swiperItemImg}>
                                            <img
                                                className={
                                                    styles.swiperItemImgInside
                                                }
                                                src={
                                                    `https:` +
                                                    switchSrc +
                                                    `?fm=webp&q=80`
                                                }
                                                data-swiper-parallax="-6.5%"
                                                alt={banner.fields.alt}
                                            />
                                        </div>
                                    </div>
                                    <section
                                        className={styles.swiperItemSection}
                                        data-swiper-parallax="-30%"
                                    >
                                        <h2
                                            className={
                                                styles.swiperItemSectionTitle
                                            }
                                        >
                                            {banner.fields.title &&
                                                banner.fields.title
                                                    .split('\n')
                                                    .map((string, index) => (
                                                        <React.Fragment
                                                            key={index}
                                                        >
                                                            {string}
                                                            <br />
                                                        </React.Fragment>
                                                    ))}
                                        </h2>
                                        <p
                                            className={
                                                styles.swiperItemSectionText
                                            }
                                        >
                                            {banner.fields.shoulder}
                                        </p>
                                        <div
                                            className={
                                                styles.swiperItemSectionLink
                                            }
                                        >
                                            <span
                                                className={
                                                    styles.swiperItemSectionLinkText
                                                }
                                            >
                                                MORE
                                            </span>
                                        </div>
                                    </section>
                                </a>
                            )}
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        );
    } else {
        return (
            <ul className={styles.swiperList}>
                {banners.items.map((banner) => {
                    const switchSrc =
                        windowWidth > breakPoint
                            ? banner.fields.pcImage.fields.file.url
                            : banner.fields.spImage.fields.file.url;
                    return (
                        <li
                            className={styles.swiperItem}
                            key={banner.fields.order}
                        >
                            <Link href={`${banner.fields.url}`}>
                                <a className={styles.swiperLink}>
                                    <div className={styles.swiperItemImgCover}>
                                        <div className={styles.swiperItemImg}>
                                            <img
                                                className={
                                                    styles.swiperItemImgInside
                                                }
                                                src={
                                                    `https:` +
                                                    switchSrc +
                                                    `?fm=webp&q=80`
                                                }
                                                alt={banner.fields.alt}
                                            />
                                        </div>
                                    </div>
                                    <section
                                        className={styles.swiperItemSection}
                                        data-swiper-parallax="-100%"
                                    >
                                        <h2
                                            className={
                                                styles.swiperItemSectionTitle
                                            }
                                        >
                                            {banner.fields.title
                                                .split('\n')
                                                .map((string, index) => (
                                                    <React.Fragment key={index}>
                                                        {string}
                                                        <br />
                                                    </React.Fragment>
                                                ))}
                                        </h2>
                                        <p
                                            className={
                                                styles.swiperItemSectionText
                                            }
                                        >
                                            {banner.fields.shoulder}
                                        </p>
                                        <div
                                            className={
                                                styles.swiperItemSectionLink
                                            }
                                        >
                                            <span
                                                className={
                                                    styles.swiperItemSectionLinkText
                                                }
                                            >
                                                MORE
                                            </span>
                                        </div>
                                    </section>
                                </a>
                            </Link>
                        </li>
                    );
                })}
            </ul>
        );
    }
});

SwiperTopKv.displayName = 'SwiperTopKv';

export default SwiperTopKv;
