import Link from 'next/link';
import { memo } from 'react';
import useUserAgent from '../../../hooks/useUserAgent';
import OrderButton from '../../button/OrderButton';
import Img from '../common/Img';
import styles from '../../../styles/components/section/top/Salads.module.scss';

const Salads = memo(({ salads }) => {
    const { device } = useUserAgent();

    return (
        <section className={styles.container}>
            <div className={styles.wrapper}>
                <div className={styles.header}>
                    <h2 className={styles.headerTitle}>
                        FEATURED <br className="u-none__pc" />
                        SALADS
                    </h2>
                    <Link href="/menu">
                        <a
                            className={`${styles.headerMore} js-hover js-hover__color__point`}
                            data-hover-in-time="600"
                            data-hover-out-time="0"
                        >
                            SEE
                            <span className={styles.headerMoreText}>MORE</span>
                        </a>
                    </Link>
                </div>
                <div className={styles.body}>
                    <ul className={styles.bodyList}>
                        {salads.items.map((data, index) => {
                            const url = data.fields.url.endsWith('/')
                                ? data.fields.url.slice(0, -1)
                                : data.fields.url;
                            return (
                                <li className={styles.bodyItem} key={index}>
                                    <Link href={`/${url}`}>
                                        <a
                                            className={`${styles.bodyLink} js-hover`}
                                            data-hover-in-time="600"
                                            data-hover-out-time="0"
                                        >
                                            <div className={styles.bodyItemImg}>
                                                <Img
                                                    hover={true}
                                                    src={
                                                        `https:` +
                                                        data.fields.image.fields
                                                            .file.url +
                                                        `?fm=webp&q=25`
                                                    }
                                                    alt={data.fields.alt}
                                                />
                                            </div>
                                        </a>
                                    </Link>
                                    <div className={styles.bodyItemContent}>
                                        <h3
                                            className={
                                                styles.bodyItemContentTitle
                                            }
                                        >
                                            {data.fields.title}
                                        </h3>
                                        <p
                                            className={
                                                styles.bodyItemContentText
                                            }
                                        >
                                            {data.fields.description}
                                        </p>
                                        <p
                                            className={
                                                styles.bodyItemContentPrice
                                            }
                                        >
                                            {data.fields.price} YEN
                                        </p>
                                        {device === 'deviceMobile' &&
                                            data.fields.dynamicLink && (
                                                <OrderButton
                                                    href={
                                                        data.fields.dynamicLink
                                                    }
                                                />
                                            )}
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        </section>
    );
});

Salads.displayName = 'Salads';

export default Salads;
