import React, { memo, useEffect, useState } from 'react';
import SwiperTopKv from '../../ui/swiper/SwiperTopKv';
import SwiperTopKvBanner from '../../ui/swiper/SwiperTopKvBanner';
import styles from '../../../styles/components/section/top/Kv.module.scss';

const Kv = memo(({ mega_banners, sub_banners }) => {
    const [siteDomein, setSiteDomein] = useState('');
    useEffect(() => {
        setSiteDomein(location.host);
    }, []);

    return (
        <div className={`${styles.container} topKv`}>
            <div className={styles.swiper}>
                <SwiperTopKv banners={mega_banners} siteDomein={siteDomein} />
            </div>
            <div className={styles.banner}>
                <SwiperTopKvBanner
                    banners={sub_banners}
                    siteDomein={siteDomein}
                />
            </div>
        </div>
    );
});

Kv.displayName = 'Kv';

export default Kv;
