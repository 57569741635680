import Link from 'next/link';
import { memo, useRef } from 'react';
import useHover from '../../../hooks/useHover';
import ArrowX from '../../icon/ArrowX';
import styles from '../../../styles/components/section/common/AlertKv.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Autoplay } from 'swiper';
SwiperCore.use([Navigation, Autoplay]);
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';

const SwiperAlertKv = memo(({ messages }) => {
    const navigationPrevRef = useRef(null);
    const navigationNextRef = useRef(null);
    // https://github.com/nolimits4web/swiper/issues/3855
    useHover();

    return (
        <Swiper
            className={styles.swiperList}
            navigation={{
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current,
            }}
            onInit={(swiper) => {
                swiper.params.navigation.prevEl = navigationPrevRef.current;
                swiper.params.navigation.nextEl = navigationNextRef.current;
                swiper.navigation.init();
                swiper.navigation.update();
            }}
            slidesPerView={1}
            loop={true}
            speed={1200}
            autoplay={{
                delay: 3900,
                disableOnInteraction: false,
            }}
        >
            {messages.items.map((message) => {
                return (
                    <SwiperSlide
                        className={styles.swiperItem}
                        key={message.fields.order}
                    >
                        {message.fields.url ? (
                            <Link href={message.fields.url}>
                                <a className={styles.swiperLink}>
                                    {message.fields.message}
                                </a>
                            </Link>
                        ) : (
                            <div className={styles.swiperLink}>
                                {message.fields.message}
                            </div>
                        )}
                    </SwiperSlide>
                );
            })}
            <button
                className={`${styles.swiperArrow} ${styles.swiperArrowNext} js-hover`}
                ref={navigationNextRef}
                aria-label="次のお知らせを見る"
            >
                <ArrowX color="#f9f8f3" />
            </button>
            <button
                className={`${styles.swiperArrow} ${styles.swiperArrowPrev} js-hover`}
                ref={navigationPrevRef}
                aria-label="前のお知らせを見る"
            >
                <ArrowX color="#f9f8f3" />
            </button>
        </Swiper>
    );
});

SwiperAlertKv.displayName = 'SwiperAlertKv';

export default SwiperAlertKv;
