import { memo } from 'react';
import ArrowZ from '../../../components/icon/ArrowZ';
import Img from '../common/Img';
import styles from '../../../styles/components/section/top/Sites.module.scss';

const Sites = memo(({ serviceBanners }) => {
    return (
        <div className={styles.container}>
            <ul className={styles.list}>
                {serviceBanners.items.map((data) => {
                    return (
                        <li className={styles.item} key={data.sys.id}>
                            <section className={styles.itemSection}>
                                <a
                                    className={`${styles.link} js-hover`}
                                    data-hover-in-time="600"
                                    data-hover-out-time="0"
                                    href={data.fields.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <div className={styles.itemImg}>
                                        <Img
                                            hover={true}
                                            src={
                                                `https:` +
                                                data.fields.image.fields.file
                                                    .url +
                                                `?fm=webp&q=50`
                                            }
                                            alt={data.fields.title}
                                        />
                                    </div>
                                    <div className={styles.itemContent}>
                                        <h2 className={styles.itemContentTitle}>
                                            <span
                                                className={
                                                    styles.itemContentTitleInner
                                                }
                                            >
                                                {data.fields.title}
                                            </span>
                                            <ArrowZ color="#0c3b2c" />
                                        </h2>
                                        <p className={styles.itemContentText}>
                                            {data.fields.description}
                                        </p>
                                    </div>
                                </a>
                            </section>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
});

Sites.displayName = 'Sites';

export default Sites;
