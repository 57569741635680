import Image from 'next/image';
import Link from 'next/link';
import React, { memo } from 'react';
import useWindowWidth from '../../../hooks/useWindowWidth';
import Img from '../../section/common/Img';
import styles from '../../../styles/components/section/top/Kv.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

const SwiperTopKvBanner = memo(({ banners, siteDomein }) => {
    const { breakPoint, windowWidth } = useWindowWidth();

    if (windowWidth > breakPoint && banners.items.length >= 4) {
        return (
            <Swiper
                className={styles.bannerList}
                slidesPerView="auto"
                centeredSlides={false}
                loop={true}
                speed={1200}
                autoplay={{
                    delay: 4100,
                    disableOnInteraction: false,
                }}
                breakpoints={{
                    768: {},
                }}
            >
                {banners.items.map((banner) => {
                    return (
                        <SwiperSlide
                            className={styles.bannerItem}
                            key={banner.sys.id}
                        >
                            {banner.fields.url.charAt(0) === '/' ? (
                                <Link href={banner.fields.url}>
                                    <a className={`${styles.bannerLink}`}>
                                        <div className={styles.bannerImg}>
                                            <Img
                                                hover={false}
                                                src={
                                                    `https:` +
                                                    banner.fields.image.fields
                                                        .file.url +
                                                    `?fm=webp&q=35`
                                                }
                                                alt={banner.fields.alt}
                                            />
                                        </div>
                                    </a>
                                </Link>
                            ) : (
                                <a
                                    href={banner.fields.url}
                                    className={`${styles.bannerLink}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <div className={styles.bannerImg}>
                                        <Img
                                            hover={false}
                                            src={
                                                `https:` +
                                                banner.fields.image.fields.file
                                                    .url +
                                                `?fm=webp&q=35`
                                            }
                                            alt={banner.fields.alt}
                                        />
                                    </div>
                                </a>
                            )}
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        );
    } else {
        return (
            <ul className={styles.bannerList}>
                {banners.items.map((banner) => {
                    return (
                        <li
                            className={styles.bannerItem}
                            key={banner.fields.url}
                        >
                            {banner.fields.url.charAt(0) === '/' ? (
                                <Link href={banner.fields.url}>
                                    <a className={`${styles.bannerLink}`}>
                                        <Image
                                            className={`${styles.bannerImg}`}
                                            src={
                                                `https:` +
                                                banner.fields.image.fields.file
                                                    .url +
                                                `?fm=webp&q=35`
                                            }
                                            layout="fill"
                                            objectFit="cover"
                                            alt={banner.fields.alt}
                                        />
                                    </a>
                                </Link>
                            ) : (
                                <a
                                    href={banner.fields.url}
                                    className={`${styles.bannerLink}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <Image
                                        className={`${styles.bannerImg}`}
                                        src={
                                            `https:` +
                                            banner.fields.image.fields.file
                                                .url +
                                            `?fm=webp&q=35`
                                        }
                                        layout="fill"
                                        objectFit="cover"
                                        alt={banner.fields.alt}
                                    />
                                </a>
                            )}
                        </li>
                    );
                })}
            </ul>
        );
    }
});

SwiperTopKvBanner.displayName = 'SwiperTopKvBanner';

export default SwiperTopKvBanner;
