import Link from 'next/link';
import { memo } from 'react';
import Img from '../common/Img';
import styles from '../../../styles/components/section/top/Initiatives.module.scss';

const Initiatives = memo(() => {
    return (
        <section className={styles.container}>
            <Link href="/initiatives">
                <a
                    className={`${styles.link} js-hover`}
                    data-hover-in-time="600"
                    data-hover-out-time="0"
                >
                    <div className={styles.img}>
                        <div className={styles.imgInner}>
                            <Img
                                hover={true}
                                src="/image/top/initiatives_bg.jpg"
                                spSrc="/image/top/sp/initiatives_bg.jpg"
                                webpSrc="/image/top/initiatives_bg.webp"
                                webpSpSrc="/image/top/sp/initiatives_bg.webp"
                                alt="INITIATIVES"
                            />
                        </div>
                    </div>
                    <div className={styles.content}>
                        <h2 className={styles.contentTitle}>INITIATIVES</h2>
                        <p className={styles.contentText}>
                            サラダに求めるのは、おいしさだけですか？いえいえ。おいしさくらいは、もう大前提でしょう。栄養価？
                            それから手軽さ？
                            そういうのも、とっくに当たり前になってきちゃっている気がします。私たちが考えるのは、外食産業を丸ごとひっくり返すための、サラダなんですよ。
                        </p>
                        <div
                            className={`${styles.contentLink} js-hover js-hover__btn__white`}
                        >
                            <span
                                className={`${styles.linkText} js-hover__btn__white-text`}
                            >
                                READ MORE
                            </span>
                        </div>
                    </div>
                </a>
            </Link>
        </section>
    );
});

Initiatives.displayName = 'Initiatives';

export default Initiatives;
